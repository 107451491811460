import React from 'react'

import {Card} from 'src/components/Common'

 const AboutPage = () => {

   return(

     <div>
       <Card>
       </Card>
     </div>

   );
 };

export default AboutPage;
