import React, {useState} from "react";
import styled from "@emotion/styled";

export const Card = styled.div`


  border-radius: 8px;
  background: white;
  margin: 40px auto;

  height: 400px;
  width: 300px;

  `
